<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">{{$t('message.add_branch')}}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <!-- <el-button type="primary">Сохранить</el-button> -->
              <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)">{{$t('message.save_and_close')}}</el-button>
              <el-button @click="close()">{{$t('message.close')}}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="center__block_form">
      <el-form ref="form" :model="form" :rules="rules" class="aticler_m" size="small">
        <el-row :gutter="25">
          <el-col :sm="12">
            <el-form-item :label="$t('message.branch')" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12">
            <el-form-item :label="$t('message.code_id')">
              <el-input v-model="form.code"  :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12">
            <el-form-item :label="$t('message.owner_branch')">
              <el-select
                  filterable
                  clearable
                  :placeholder="$t('message.owner_branch')"
                  size="mini"
                  v-model="form.owner_branch_id"
              >
                <el-option
                    v-for="item in owner_branches"
                    :key="item.name + item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- end col -->
        </el-row>
        <!-- end el-row -->
      </el-form>
    </div>
  </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex';
import form from '@/utils/mixins/form';
import drawerChild from '@/utils/mixins/drawer-child';
export default {
  mixins:[form,drawerChild],
  data(){
    return {}
  },
  created(){},
  computed: {
    ...mapGetters({
      rules: 'branches/rules',
      model: 'branches/model',
      columns: 'branches/columns',
      lastId: 'branches/lastId',
      branches: 'branches/inventory',
      owner_branches: "ownerBranches/list",
    })
  },
  methods:{
    ...mapActions({
      save: 'branches/store',
      empty: 'branches/empty',
      getLastId: 'branches/getLastId',
      loadOwnerBranches: 'ownerBranches/index',
    }),
    afterOpened(){
      this.form = JSON.parse( JSON.stringify(this.model) );
      if (!this.lastId) {
        this.getLastId().then(res => {
          this.form.code = this.getFormatted(res.data.last_id)})
      }else{
        this.form.code = this.getFormatted(this.lastId);
      }
      this.loadOwnerBranches();
      },
      getFormatted(num){
        if(num < 10) {
          return '000 000 00'+num;
        }else if(num >=10 && num<100){
          return '000 000 0'+num;
        }
        else if(num >=100 && num<1000){
          return '000 000 '+num;
        }
        else if(num >=1000 && num<10000){
          return '000 00'+num;
        }
        else if(num >=10000 && num<100000){
          return '000 0'+num;
        }
        else if(num >=100000 && num<1000000){
          return '000 '+num;
        }
        else if(num >=1000000 && num<10000000){
          return '00'+num;
        }
        return num;
      },
      afterClosed(){
        this.empty()
      },
      submit(close = true){
        this.$refs['form'].validate((valid) => {
          if (valid){
            this.loadingButton = true;
            this.save(this.form)
              .then(res => {
                this.loadingButton = false;
                this.$notify({
                  title: 'Успешно',
                  type: "success",
                  offset: 130,
                  message: res.message
                });
                this.parent().listChanged()
                if (close == true) {
                  this.close();
                }
              })
              .catch(err => {
                this.loadingButton = false;
                this.$notify({
                  title: 'Ошибка',
                  type: "error",
                  offset: 130,
                  message: err.error.message
                });
              });
          }
        });
      }
    }
  }
</script>
